import React, { useEffect, useState } from "react";
import "./style.css";
import {
  useGetArtistsQuery,
  useGetArtsQuery,
  useGetDashboardQuery,
  useGetOrdersQuery,
} from "../../store/api/api";
import ScoreCard from "../../components/score-card";
import DynamicTable from "../../components/table";
import {
  extractArtData,
  extractOrderData,
} from "../../components/table/format-data";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../components/loader";
import { useDebounce } from "../../hooks/debounce";
import axios from "axios";
import { useSelector } from "react-redux";
const OrdersPage = () => {
  const [page, setPage] = useState({ pageNo: 1, pageSize: 10 });
  const locatation = useLocation();

  const [searchInput, setSearchInput] = useState("");
  const debonceValue = useDebounce(searchInput);
  const ArtFilters = {
    genre: [],
    sortBy: {
      name: "lastest",
      value: "-createdAt",
    },
    price: {
      min: 0,
      max: false,
    },
    onlySale: "false",
    fixedPrice: "false",
    timedAuction: "false",
    owner: "all",
    artist: "all",
    dimensions: {
      width: [],
      height: [],
      depth: [],
      weight: [],
    },
    id: debonceValue,
  };
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: orders,
    refetch,
    isFetching,
    isLoading,
  } = useGetOrdersQuery({
    pageNo: page.pageNo,
    pageSize: page.pageSize,
    filters: ArtFilters,
    domain: searchParams.get("domain"),
  });

  useEffect(() => {
    if (orders?.data) {
      refetch();
    }
  }, [locatation.pathname]);

  return (
    <div className="ArtPageContainer">
      <h1>Orders: </h1>
      <div className="search-with-id">
        <input
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
          type="text"
          placeholder="enter id"
          name=""
          id=""
        />
        <button
          onClick={(e) => {
            //@ts-ignore
            setSearchInput("");
          }}
        >
          Clear
        </button>
      </div>
      {isFetching || isLoading ? <Loader height={250} /> : <> </>}
      <DynamicTable
        data={extractOrderData(orders?.data)}
        pagination={orders?.pagination}
        onPageChange={(page) => {
          setPage({ pageNo: page.pageNo, pageSize: page.pageSize });
        }}
        moreDetailAction={(id) => {
          navigate("/orders/" + id);
        }}
        ctaText="Order Detail"
      />
    </div>
  );
};

export default OrdersPage;
