import React from "react";
import "./style.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetSingleOrderQuery } from "../../store/api/api";
import axios from "axios";
import OrderActionModal from "./components/DeleteModal/OrderActionModal";
type Props = {};

interface IOffer {
  artId: string;
  artName: string;
  offerType:
    | "S_FIXED_PRICE"
    | "OS_ENGLISH_AUCTION"
    | "OS_DUTCH_AUCTION"
    | "OS_BUY_OFFER";
  orderId: string;
  domain: string;
  status:
    | "ORS_IN_PROGRESS"
    | "ORS_APPROVED_ON_BLOCKCHAIN"
    | "ORS_REJECTED_ON_BLOCKCHAIN"
    | "ORS_DONE"
    | "ORS_REJECTED";
}
const SingleOrder = (props: Props) => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const { id } = useParams();
  const { data, refetch } = useGetSingleOrderQuery<{ data: IOffer }>({
    orderId: id,
  });
  console.log("🚀 ~ SingleOrder ~ data:", data);
  const [ActioneModalState, setActioneModalState] = React.useState({
    isOpen: false,
    action: () => {},
    CtaText: "",
  });
  const orderAction = async (id, action = "approve") => {
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_MASTERPIEC_API}/order/${id}/${action}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );

      console.log(res);
      setActioneModalState({
        isOpen: false,
        action: () => {},
        CtaText: "",
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  if (!data) {
    return null;
  }
  return (
    <div className="single-order-contianer">
      <OrderActionModal
        isActive={ActioneModalState.isOpen}
        onClose={() => {
          setActioneModalState({
            isOpen: false,
            action: () => {},
            CtaText: "",
          });
        }}
        action={ActioneModalState.action}
        CtaText={ActioneModalState.CtaText}
      />
      <div className="info">
        <h1>Name: {data?.artName}</h1>
        <h1>Domain: {data?.domain}</h1>
        <h1>Order Type: {data?.offerType}</h1>
        <h1>Status: {data?.status}</h1>
        <h1>Order Id: {data?.orderId}</h1>

        {data?.status == "ORS_IN_PROGRESS" && (
          <div className="actions">
            <button
              onClick={() => {
                setActioneModalState({
                  isOpen: true,
                  action: () => orderAction(data?.orderId, "reject"),
                  CtaText: "Reject",
                });
              }}
            >
              Reject
            </button>
            <button
              className="postitive-btn"
              onClick={() => {
                setActioneModalState({
                  isOpen: true,
                  action: () => orderAction(data?.orderId, "approve"),
                  CtaText: "Approve",
                });
              }}
            >
              Accept
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleOrder;
