import React, { useContext, useState } from "react";
import {
  ArtBoardIcon,
  LoginIcon,
  NavyProfileIconHeader,
  arrowIcon,
  homeIcon,
  navyLogo,
  navySearchIcon,
  newNavyLogo,
} from "../../assets";
import { useNavigate } from "react-router-dom";
import "./style.css";
import SearchBox from "../search-box";
import { useSelector } from "react-redux";
const Header = () => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const navigate = useNavigate();
  console.log("🚀 ~ HeaderPages ~ navigate:", window.history)
  const [openModal, setOpenModal] = useState(false);
  
  return (
    <>
      <header className="HeaderContainer">
        <img
          width={189}
          height={"fit-content"}
          onClick={() => navigate("/")}
          src={newNavyLogo}
          alt={`navyLogo`}
          style={{ transform: "translateY(-7px)" }}
        />
        <div className="searchHeaderContainer">
          <SearchBox />
          <button>
            <img src={navySearchIcon} alt={`navySearchIcon`} />
          </button>
        </div>
        <button onClick={() => navigate(-1)} className="BackHeader">
          <img src={arrowIcon} alt={`arrowIcon`} />
          Back
        </button>
        {accessToken ? (
          <>
            <button
              onClick={() => setOpenModal(true)}
              className="LoginHeaderCta-HeaderCom"
            >
              Artboard
            </button>
          </>
        ) : (
          <button
            className="LoginHeaderCta-HeaderCom"
            // onClick={() => connect()}
          >
            Login
          </button>
        )}
      </header>


    </>
  );
};

export default Header;
