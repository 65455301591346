import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SizeDetector from "./hooks/size-detector";
import Loader from "./components/loader";
import Guard from "./utils/guard";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import SingleArt from "./pages/single-art";
import OrdersPage from "./pages/orders";
import SingleOrder from "./pages/single-order";
const DashboardPage = lazy(() => import("./pages/dashboard"));
const LoginPage = lazy(() => import("./pages/login"));
const ArtistsPage = lazy(() => import("./pages/artists"));
const ArtsPage = lazy(() => import("./pages/arts"));
function App() {
  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };
  const pathname = useLocation()?.pathname;
  const { isMobile } = SizeDetector();
  useEffect(() => {
    window.scrollTo(0, 0);
    enableScroll();
  }, [pathname]);
  return (
    <>
      <Guard />
      {pathname !== "/auth" ? (
        <>
          <Header />
          <Sidebar />
        </>
      ) : null}
      <Suspense fallback={<Loader height={"100vh"} />}>
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/auth" element={<LoginPage />} />
          <Route path="/users" element={<ArtistsPage />} />
          <Route path="/orders" element={<OrdersPage />} />
          <Route path="/orders/:id" element={<SingleOrder />} />
          <Route path="/arts" element={<ArtsPage />} />
          <Route path="/art/:id" element={<SingleArt />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
