import React from "react";
import "./style.css";
type Props = {
  isActive: boolean;
  onClose: () => void;
  action: (item: any) => void;
};
const SoldModal = ({ isActive, onClose, action }: Props) => {
  const [grade, setGrade] = React.useState("");
  return (
    <div
      className={`sold-modal-overlay ${isActive ? "active" : ""}`}
      onClick={onClose}
    >
      <div className="sold-modal" onClick={(e) => e.stopPropagation()}>
        {/* Your modal content goes here */}

        <p>Tag this Item Sold </p>

        <div className="modal-action">
          <button onClick={onClose} className="cancel">
            Cancel
          </button>
          <button onClick={(e) => action(grade)}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default SoldModal;
