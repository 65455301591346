export const handleCopyClick = (textToCopy:any, setCopyMessage) => {
  const textArea = document.createElement("textarea");
  textArea.value = textToCopy;

  // Make the textarea invisible so it doesn't affect the UI
  textArea.style.position = "fixed";
  textArea.style.opacity = "0";

  // Add the textarea to the DOM
  document.body.appendChild(textArea);

  // Select the text inside the textarea
  textArea.select();

  try {
    // Execute the copy command
    const successful = document.execCommand("copy");
    const message = successful ? textToCopy : "Unable to copy!";
    setCopyMessage(message);

    // Clear the copied message after 3 seconds
    setTimeout(() => {
      setCopyMessage(null);
    }, 3000); // 3 seconds (3000 milliseconds)
  } catch (err) {
    console.error("Oops, unable to copy", err);
  }

  // Remove the textarea from the DOM
  document.body.removeChild(textArea);
};
