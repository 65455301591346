import React, { useState } from "react";
import "./style.css";
import { enableCopiData } from "./format-data";
import { CheckedCopy, copyIcon } from "../../assets";
import { handleCopyClick } from "../../utils/copy";
import TablePagination from "./table-pagination";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
interface DynamicTableProps {
  data: any[];
  pagination: any;
  onPageChange?: any;
  moreDetailAction?: Function;
  ctaText?: string;
}
const DynamicTable: React.FC<DynamicTableProps> = ({
  data,
  pagination,
  onPageChange,
  moreDetailAction,
  ctaText = "More Detail",
}) => {
  const [copyMessage, setCopyMessage] = useState();
  const locatation = useLocation();
  console.log("🚀 ~ locatation:", locatation);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const headers = Object.keys(data[0]);
  return (
    <div className="TableContainer">
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header) => (
                <td
                  key={header}
                  onClick={() => {
                    if (locatation?.pathname == "/users") return;
                    if (!enableCopiData.includes(header)) {
                      navigate(
                        `/art/${row?.id}?domain=${
                          searchParams.get("domain") || "art"
                        }`
                      );
                    }
                  }}
                >
                  <div className="tableBoxValue">
                    {enableCopiData?.includes(header)
                      ? row[header]?.slice(0, 5) + "..."
                      : row[header]}
                    {enableCopiData?.includes(header) ? (
                      <button
                        onClick={() =>
                          handleCopyClick(row[header], setCopyMessage)
                        }
                      >
                        <img
                          style={{ marginLeft: "5px" }}
                          src={
                            copyMessage == row[header] ? CheckedCopy : copyIcon
                          }
                        />
                      </button>
                    ) : null}
                  </div>
                </td>
              ))}
              <td>
                {" "}
                <div className="tableBoxValue">
                  <button
                    onClick={() => {
                      if (locatation?.pathname == "/users") {
                        moreDetailAction(row?.id);
                      }
                      if (row?.orderId) {
                        moreDetailAction(row?.orderId);
                      }
                    }}
                  >
                    {ctaText}
                  </button>
                </div>{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </table>{" "}
      {onPageChange ? (
        <TablePagination
          count={Math.ceil(
            parseInt(pagination?.totalItemsCount) /
              parseInt(pagination?.pageSize)
          )}
          onPageChange={onPageChange}
        />
      ) : null}
    </div>
  );
};

export default DynamicTable;
