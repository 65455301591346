import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetSearchQuery } from "../../store/api/api";
import { useDebounce } from "../../hooks/debounce";
import Loader from "../loader";

const ShowResaul = ({ setShowResult, searchValue }) => {
  const navigate = useNavigate();
  const [page] = useState(1);
  const DebouncedSearch = useDebounce<any>(searchValue, 500);
  const { data, isLoading, isFetching } = useGetSearchQuery(
    { searchValue: DebouncedSearch, page: 5 },
    {
      skip: !DebouncedSearch,
    }
  );
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click is outside the component
      if (!event.target.closest(".SearchInputDropDown")) {
        setShowResult(false);
      }
    };

    // Attach event listener to the document body
    document.body.addEventListener("mousedown", handleOutsideClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.body.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setShowResult]);
  return (
    <div className="SearchInputDropDown">
      {isLoading||isFetching ? (
        <div style={{ position: "absolute", inset: "0" }}>
          <Loader height={"70%"} />
        </div>
      ) : (
        <div className="resultSearch">
          <h5>Arts</h5>
          {data?.arts?.length == 0 && <p> No Arts's Found. </p>}
          {data?.arts?.slice(0, 2)?.map((art, i) => (
            <button
              onClick={() => {
                navigate(`/art/${art?.art?.id}`);
                setShowResult(false);
              }}
            >
              {/* <img src={documentIcon} /> */}
              {art?.art?.name}
            </button>
          ))}
          <h5>Artist's</h5>
          {data?.profiles?.length == 0 && <p> No Artist's Found. </p>}
          {data?.profiles?.slice(0, 2)?.map((profile, i) => (
            <button
              onClick={() => {
                navigate(`/profile/${profile?.publicKeys[0]?.key}`);
                setShowResult(false);
              }}
            >
              {/* <img src={person} /> */}
              {profile?.firstName} {profile?.lastName}
            </button>
          ))}
        </div>
      )}
      {(data?.arts?.length > 0 || data?.profiles?.length > 0) && (
        <button
          onClick={() => {
            navigate(`/search?q=${searchValue}`);
            setShowResult(false);
          }}
        >
          Show More...
        </button>
      )}
    </div>
  );
};

export default ShowResaul;
