import React from "react";
import "./style.css";
type Props = {
  isActive: boolean;
  onClose: () => void;
  action: () => void;
  CtaText: string;
};
const OrderActionModal = ({ isActive, onClose, action, CtaText }: Props) => {
  return (
    <div
      className={`delete-modal-overlay ${isActive ? "active" : ""}`}
      onClick={onClose}
    >
      <div className="delete-modal" onClick={(e) => e.stopPropagation()}>
        {/* Your modal content goes here */}

        <button onClick={onClose} className="cancel">
          Cancel
        </button>
        <button onClick={action}>{CtaText}</button>
      </div>
    </div>
  );
};

export default OrderActionModal;
