import React, { useEffect } from "react";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
const Sidebar = () => {
  const locatation = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routes = [
    { route: "/", name: "dashboard", icon: "" },
    { route: "/users", name: "users", icon: "" },
    { route: "/orders", name: "orders", icon: "" },
    { route: "/arts?domain=art", name: "arts", icon: "" },
    { route: "/arts?domain=academy", name: "academy", icon: "" },
    { route: "/arts?domain=jewellery", name: "jewellery", icon: "" },
    { route: "/arts?domain=carpet", name: "carpet", icon: "" },
    { route: "/arts?domain=antique", name: "antique", icon: "" },
    { route: "/arts?domain=handcraft", name: "handcrafts", icon: "" },
    // { route: "/artists", name: "artists", icon: "" },
  ];
  useEffect(() => {
    console.log(locatation);
  }, [locatation]);
  return (
    <div className="sidebarContainer">
      {routes?.map((route) => (
        <button
          className={`${
            locatation.pathname + locatation.search == route?.route && "active"
          }`}
          onClick={() => navigate(route?.route)}
        >
          {route?.name}
        </button>
      ))}
      <button
        onClick={() => {
          dispatch({ type: "auth/logout" });
          navigate("/auth");
        }}
      >
        logOut
      </button>
    </div>
  );
};

export default Sidebar;
