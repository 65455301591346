import React, { useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "../../hooks/debounce";
import "./style.css";
import ShowResaul from "./show-resaul";
import SizeDetector from "../../hooks/size-detector";

const SearchBox = () => {
  const { isMobile } = SizeDetector();
  const [showResult, setShowResult] = useState(false);
  const [searchValue, setSearchValue] = useState<any>(null);
  const navigate = useNavigate();
  const [searchParams1, setSearchParams] = useSearchParams({
    q:''
  });

  const searchParams = useLocation();
  const handelQuerySearch = (value: any) => {
    setSearchValue(value)
    if (searchParams?.pathname == "/search") {
      setSearchParams({q:value});
    }
  };
  const ref =useRef()
  return (
    <>
      <input
        defaultValue={searchParams1.get('q')}
        onFocus={() => setShowResult(true)}
        onChange={(e) => {
          handelQuerySearch(e.target.value);
        }}
        // autoFocus={isMobile}
        placeholder="Search for artworks and artists"
        onKeyUp={(e) => {
          if (e.key === "Enter" && searchParams1.get('q')?.length >= 3) {
            navigate(`/search?q=${searchParams1.get('q')}`);
          }
        }}
      />
      {showResult &&
        searchParams?.pathname !== "/search" &&
        searchValue?.length >= 3 && (
          <ShowResaul setShowResult={setShowResult} searchValue={searchValue} />
        )}
    </>
  );
};

export default SearchBox;
