const extractUserData = (data) => {
  return data?.map((user) => ({
    id: user?.id,
    // name: `${user?.firstName} ${user?.lastName}`,
    username: user?.username,
    // headline: user?.headline,
    email: user?.email,
    // numArts: user?.numArts,
    // country: user?.location?.country,
    // city: user?.location?.city,
    publicKey: user?.publicKeys?.map((pk) => pk?.key)[0]?.toLowerCase(),

    // phone: user?.contactInfo?.phones?.map((phone) => phone?.phone)?.join(", "),
  }));
};

const extractArtData = (data) => {
  return data?.map((item) => ({
    id: item?.art?.id,
    name: item?.art?.name,
    artists: item?.artists
      ?.map((artist) => `${artist?.firstName} ${artist?.lastName}`)
      .join(", "),
    description: item?.art?.description,
    height: item?.art?.dimensions?.height,
    width: item?.art?.dimensions?.width,
    weight: item?.art?.dimensions?.weight,
    // owners: item?.owners?.map((owner) => owner?.publicKey)?.join(", "),
  }));
};
const extractOrderData = (data) => {
  return data?.map((item) => ({
    id: item?.productId,

    name: item?.artName,
    offerType: item?.offerType,
    orderId: item?.orderId,
    domain: item?.domain,
    // owners: item?.owners?.map((owner) => owner?.publicKey)?.join(", "),
  }));
};
const enableCopiData = ["publicKey", "id", "email"];
export { extractArtData, extractUserData, enableCopiData, extractOrderData };
