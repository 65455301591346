import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";
import SizeDetector from "../../../hooks/size-detector";
import { arrowSliderIcon } from "../../../assets";

const TablePagination = ({ count, onPageChange }) => {
  const { isMobile } = SizeDetector();
  const [currentPage, setCurrentPage] = useState(1);
  const [scrollChange, setScrollchange] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  // useEffect(() => {
  //   const changeNavbarColor = () => {
  //     if (window.scrollY >= 100) {
  //       setScrollchange(true);
  //     } else {
  //       setScrollchange(false);
  //     }
  //   };
  //   window.addEventListener("scroll", changeNavbarColor);
  // }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [count, currentPage]);
  useEffect(() => {
    if (onPageChange) {
      onPageChange({ pageNo: currentPage, pageSize: pageSize });
    }
  }, [currentPage, pageSize]);
  const displayedPages = 10; // Adjust this number based on your requirements

  // Calculate the range of pages to display around the current page
  let startPage =
    count > 10 ? Math.max(1, currentPage - Math.floor(displayedPages / 2)) : 1;
  console.log("🚀 ~ Pagination ~ startPage:", startPage);
  let endPage = Math.min(count, startPage + displayedPages - 1);
  console.log("🚀 ~ Pagination ~ endPage:", endPage);

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < count) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div
      className={`paginationContainer ${
        scrollChange && isMobile ? "paginationChange" : ""
      }`}
    >
      <select
        onChange={(e) => {
          setPageSize(parseInt(e.target.value));
          setCurrentPage(1);
        }}
      >
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
      <button
        onClick={handlePrev}
        className="prevPaginationCta"
        disabled={currentPage === 1}
      >
        <img
          style={{ opacity: currentPage == 1 && "0.5" }}
          src={arrowSliderIcon}
          alt="arrowSliderIcon"
        />
      </button>
      {startPage > 1 && <span>...</span>}
      {Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      ).map((pageNumber) => (
        <button
          key={pageNumber}
          className={`${
            currentPage?.toString() === pageNumber?.toString()
              ? "ActivePageItem"
              : "deActivePageItem"
          }`}
          onClick={() => {
            setCurrentPage(pageNumber);
          }}
        >
          {pageNumber}
        </button>
      ))}

      {/* Display ellipsis if the distance between the last item page and the largest displayed item page is more than 10 */}
      {count - endPage > 0 && <span>...</span>}

      <button onClick={handleNext} disabled={currentPage == count}>
        <img
          style={{ opacity: currentPage == count && "0.5" }}
          src={arrowSliderIcon}
          alt="next"
        />
      </button>
    </div>
  );
};

export default TablePagination;
