import React, { lazy } from "react";
import { useGetSingleArtQuery } from "../../store/api/api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./style.css";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../../components/loader";
import SoldModal from "./components/SoldModal/SoldModal";
// import DeleteModal from "./components/DeleteModal/DeleteModal";
// import GradeModal from "./components/GradeModal";

const GradeModal = lazy(() => import("./components/GradeModal/GradeModal"));
const DeleteModal = lazy(() => import("./components/DeleteModal/DeleteModal"));
type Props = {};

const SingleArt = (props: Props) => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);

  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const [DeleteModalState, setDeleteModalState] = React.useState({
    isOpen: false,
    action: () => {},
  });
  const [GradeModalState, setGradeModalState] = React.useState({
    isOpen: false,
    action: (item) => {},
  });
  const [SoldModalState, setSoldModalState] = React.useState({
    isOpen: false,
    action: (item) => {},
  });
  const {
    data: art,
    isFetching,
    isLoading,
    refetch,
  } = useGetSingleArtQuery({
    artId: id,
    domain: searchParams.get("domain"),
  });

  console.log("🚀 ~ file: index.tsx ~ line 17 ~ SingleArt ~ data", art);
  const deleteArt = async () => {
    try {
      let res = await axios.delete(
        process.env.REACT_APP_MASTERPIEC_API +
          `/items:${searchParams.get("domain")}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(res);
      navigate(`/arts?domain=${searchParams.get("domain")}`);
    } catch (error) {
      console.log("🚀 ~ file: index.tsx ~ line 64 ~ SingleArt ~ error", error);
    }
  };
  const UpdateGrade = async (grade) => {
    try {
      let res = await axios.put(
        process.env.REACT_APP_MASTERPIEC_API +
          `/items:${searchParams.get("domain")}/${id}/grade/${grade}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(res);
      setGradeModalState({
        isOpen: false,
        action: () => {},
      });
      refetch();
    } catch (error) {
      console.log("🚀 ~ file: index.tsx ~ line 64 ~ SingleArt ~ error", error);
    }
  };
  const TagSold = async () => {
    try {
      let res = await axios.put(
        process.env.REACT_APP_MASTERPIEC_API +
          `/items:${searchParams.get("domain")}/${id}/sold`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(res);
      setSoldModalState({
        isOpen: false,
        action: () => {},
      });
      refetch();
    } catch (error) {
      console.log("🚀 ~ file: index.tsx ~ line 64 ~ SingleArt ~ error", error);
    }
  };
  return (
    <div className="single-art-container">
      {isFetching || isLoading ? (
        <Loader height={250} />
      ) : (
        <>
          <DeleteModal
            isActive={DeleteModalState.isOpen}
            onClose={() => {
              setDeleteModalState({ isOpen: false, action: () => {} });
            }}
            action={DeleteModalState.action}
          />
          <GradeModal
            CurrentGrade={art?.art?.grade}
            isActive={GradeModalState.isOpen}
            onClose={() => {
              setGradeModalState({ isOpen: false, action: () => {} });
            }}
            action={GradeModalState.action}
          />
          <SoldModal
            isActive={SoldModalState.isOpen}
            onClose={() => {
              setSoldModalState({ isOpen: false, action: () => {} });
            }}
            action={SoldModalState.action}
          />
          <img
            style={{
              width: "450px",
              height: "auto",
              objectFit: "contain",
            }}
            src={`${process.env.REACT_APP_MASTERPIEC_CLOUDIMG}/${
              process.env.REACT_APP_MASTERPIEC_API_MAIN
            }/files?id=${
              art?.art?.assets?.filter(
                (asset) => asset.type === "AT_ART_THUMBNAIL"
              )[0]?.fileId || art?.art?.nftFile?.id
            }&force_format=webp&q=20&optipress=3`}
            alt={`art-cover`}
            loading="lazy"
          />
          <div className="info">
            <h1>Name: {art?.art?.name}</h1>
            <h1>status: {art?.art?.status}</h1>
            <h1>{art?.offer && "in sale"}</h1>
            <p>
              Artists:{" "}
              {art?.artists.map((artist) => artist?.firstName).join(",")}
            </p>
            <h1>Current Grade: {art?.art?.grade}</h1>

            <div className="actions">
              <button
                onClick={() => {
                  setDeleteModalState({
                    isOpen: true,
                    action: deleteArt,
                  });
                }}
              >
                Delete
              </button>
              <button
                className="postitive-btn"
                onClick={() => {
                  setGradeModalState({
                    isOpen: true,
                    action: UpdateGrade,
                  });
                }}
              >
                Chenge Grade
              </button>
              {art?.art?.status !== "PS_SOLD" && (
                <button
                  className="postitive-btn sold"
                  onClick={() => {
                    setSoldModalState({
                      isOpen: true,
                      action: TagSold,
                    });
                  }}
                >
                  Tag the Item Sold
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleArt;
