import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
const getAccessToken = (state) => {
  // Use state to access the access token from the Redux store
  return state.auth.accessToken;
};

export const Api = createApi({
  reducerPath: "public",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MASTERPIEC_API,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getAccessToken(getState()); // Get the access token
      if (accessToken) {
        // Add the access token to the headers
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),

  refetchOnReconnect: true,
  tagTypes: [
    "explore",
    "SingleArt",
    "SingleOrder",
    "Dashboard",
    "exploreArtists",
    "exploreOrders",
    "exploreOwners",
  ],
  endpoints: (builder) => ({
    getArts: builder.query<any, any>({
      query: ({ pageNo, pageSize, filters, domain = "art" }) => {
        const queryParams = [
          `pageNo=${pageNo}`,
          `pageSize=${pageSize}`,
          `sort=${filters.sortBy.value}`,
        ];

        if (filters?.onlySale === "true") {
          queryParams.push(
            `filters[type]=${filters?.fixedPrice == "true" ? "0" : ""}${
              filters?.fixedPrice == "true" && filters?.timedAuction == "true"
                ? ","
                : ""
            }${filters?.timedAuction == "true" ? "1" : ""}`
          );
        }
        if (filters?.id) {
          queryParams.push(`filters[id]=${filters.id}`);
        }

        if (filters.genre.length > 0) {
          queryParams.push(`filters[genre]=${filters.genre.join(",")}`);
        }

        const addDimensionFilters = (dimension, paramName) => {
          if (filters?.dimensions?.[dimension]?.length > 0) {
            queryParams.push(
              `filters[${paramName}]=${filters.dimensions[dimension][0]},${filters.dimensions[dimension][1]}`
            );
          }
        };

        addDimensionFilters("width", "width");
        addDimensionFilters("height", "height");
        addDimensionFilters("depth", "depth");
        addDimensionFilters("weight", "weight");

        if (filters?.price?.max) {
          queryParams.push(
            `filters[price]=${filters.price.min},${filters.price.max}`
          );
        }

        if (filters?.artist !== "all") {
          queryParams.push(`filters[artist]=${filters.artist.toLowerCase()}`);
        }

        if (filters?.owner !== "all") {
          queryParams.push(`filters[owner]=${filters.owner.toLowerCase()}`);
        }

        return `/items:${domain}?${queryParams.join("&")}`;
      },
      providesTags: ["explore"],
    }),
    getArtists: builder.query<any, any>({
      query: ({ pageNo, pageSize, filters }) =>
        `/users?pageNo=${pageNo}&pageSize=${pageSize}${
          filters?.name ? `&filters[firstName]=${filters?.name}` : ""
        }&sort=${filters?.sortBy}`,
      providesTags: ["exploreArtists"],
    }),
    getOrders: builder.query<any, any>({
      query: ({ pageNo, pageSize, filters }) =>
        `/orders?pageNo=${pageNo}&pageSize=${pageSize}${
          filters?.name ? `&filters[firstName]=${filters?.name}` : ""
        }`,
      providesTags: ["exploreOrders"],
    }),
    getOwners: builder.query<any, any>({
      query: ({ pageNo, pageSize, filters }) =>
        `/owners:art?pageNo=${pageNo}&pageSize=${pageSize}${
          filters?.name ? `&filters[firstName]=${filters?.name}` : ""
        }`,
      providesTags: ["exploreOwners"],
    }),
    getDashboard: builder.query<any, any>({
      query: () => `/dashboard`,
      providesTags: ["Dashboard"],
    }),
    getSingleArt: builder.query<any, any>({
      query: ({ artId, domain = "art" }) => `/items:${domain}/${artId}`,
      providesTags: ["SingleArt"],
    }),
    getSingleOrder: builder.query<any, any>({
      query: ({ orderId }) => `/orders/${orderId}`,
      providesTags: ["SingleOrder"],
    }),
  }),
});
export const ProfileDetail = createApi({
  reducerPath: "UserProfile",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MASTERPIEC_API,
  }),
  refetchOnReconnect: true,
  tagTypes: ["profile", "profileArts"],
  endpoints: (builder) => ({
    getProfileDetail: builder.query({
      query: ({ identifier }) => `/profiles/identity/${identifier}`,
      providesTags: ["profile"],
    }),
    getProfileArts: builder.query({
      query: ({ publicKey, filters }) =>
        `/profile/${publicKey}/arts:art?${
          filters === "true" ? `&pagination.filters[type]=[0]` : ""
        }`,
      providesTags: ["profileArts"],
    }),
  }),
});

export const ProtectAPIs = createApi({
  reducerPath: "ProtectAPIs",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MASTERPIEC_API_MAIN,
    prepareHeaders: (headers, { getState }) => {
      // const accessToken = getAccessToken(getState()); // Get the access token
      // if (accessToken) {
      //   // Add the access token to the headers
      //   headers.set("Authorization", `Bearer ${accessToken}`);
      // }
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: [
    "explore",
    "SingleArt",
    "HomeApi",
    "exploreArtists",
    "exploreOwners",
  ],
  endpoints: (builder) => ({
    getArts: builder.mutation<any, any>({
      query: ({ pageNo, pageSize, filters, domain }) => {
        var body: any = {
          pageNo: pageNo.pageNo,
          pageSize: pageNo.pageSize,
          // sort: {
          //   [filters.sortBy.key]: filters.sortBy.value,
          // },
          filter: filters,
        };
        if (filters?.onlySale === "true") {
          const sale = {
            key: "offer.price.amount",
            operator: "EXISTS",
          };
          const offerType = {
            key: "offer.type",
            value: `${filters?.fixedPrice == "true" ? 0 : ""}${
              filters?.fixedPrice == "true" && filters?.timedAuction == "true"
                ? ","
                : ""
            }${filters?.timedAuction == "true" ? "1" : ""}`,
            operator: "IN",
          };

          // Add filters to the body
          body.filter.push(sale);
          body.filter.push(offerType);
        } else {
          // Remove the filters related to sale and offerType
          body.filter = body.filter.filter(
            (item) =>
              item.key !== "offer.price.amount" && item.key !== "offer.type"
          );
        }

        if (filters?.genre?.length > 0) {
          const genre = {
            key: "genres._id",
            value: `${filters.genre.join(",")}`,
            operator: "IN",
          };
          body.filter.push(genre);
        } else {
          //  remove genere to filter
          body.filter = body.filter.filter((item) => item.key !== "genres._id");
        }

        if (filters?.price?.max) {
          const price = {
            key: "offer.price.amount",
            value: `${filters.price.min},${filters.price.max}`,
            operator: "BETWEEN",
          };

          // Add price filter to the body
          body.filter.push(price);
        } else {
          // Remove the price filter if it exists
          body.filter = body.filter.filter(
            (item) => item.key !== "offer.price.amount"
          );
        }

        // if (filters?.artist !== "all") {
        //   // queryParams.push(`filters[artist]=${filters.artist.toLowerCase()}`);
        //   const artist = {
        //     key: "artists._id",
        //     value: filters.artist,
        //     operator: "EQUAL",
        //   };
        //   console.log("🚀 ~ artist:", artist);
        //   body.filter.push(artist);
        // } else {
        //   body.filter = body.filter.filter(
        //     (item) => item.key !== "artists._id"
        //   );
        // }
        if (body.filter?.length == 0) delete body.filter;
        console.log(body);

        return {
          url: `/products/fetch`,
          method: "POST",
          headers: {
            "x-domain-name": domain,
          },
          body,
        };
      },
      invalidatesTags: ["explore"],
    }),
    getArtists: builder.mutation<any, any>({
      query: ({ pageNo, pageSize, filters, validated = "true" }) => {
        var body: any = {
          pageNo,
          pageSize,
          filter: [],
        };
        if (filters?.price?.max) {
          const price = {
            key: "offer.price.amount",
            value: `${filters.price.min},${filters.price.max}`,
            operator: "BETWEEN",
          };

          // Add price filter to the body
          body.filter.push(price);
        } else {
          // Remove the price filter if it exists
          body.filter = body.filter.filter(
            (item) => item.key !== "offer.price.amount"
          );
        }
        return {
          url: `/artists/fetch`,
          method: "POST",

          body,
        };
      },
      invalidatesTags: ["exploreArtists"],
    }),
    getOwners: builder.query<any, any>({
      query: ({ pageNo, pageSize, filters }) =>
        `/owners:art?pageNo=${pageNo}&pageSize=${pageSize}${
          filters?.name ? `&filters[firstName]=${filters?.name}` : ""
        }`,
      providesTags: ["exploreOwners"],
    }),
    getHomeApi: builder.query<any, any>({
      query: ({ domain }) => ({
        url: `/home`,
        headers: {
          "x-domain-name": domain,
        },
      }),
      providesTags: ["HomeApi"],
    }),
    // /products/{productId}
    getSingleArt: builder.query<any, any>({
      query: ({ artId }) => `/products/${artId}`,
      providesTags: ["SingleArt"],
    }),
  }),
});
export const nftAcount = createApi({
  reducerPath: "NFTs",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.rarible.org/v0.1/items/byOwner",
    prepareHeaders: (headers) => {
      // Set the X-API-KEY header
      headers.set("X-API-KEY", "a84bd16f-8e4f-4bad-a9ee-fa6a87053e81");
      return headers;
    },
  }),
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getNFTs: builder.query({
      query: ({ walletAddress }) =>
        `/?blockchains=POLYGON&owner=ETHEREUM:${walletAddress}&size=500`,
    }),
    // other API 2 endpoints...
  }),
});
export const SearchApi = createApi({
  reducerPath: "search",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_MASTERPIEC_API }),
  tagTypes: ["searchValue"],
  endpoints: (builder) => ({
    getSearch: builder.query<any, any>({
      query: ({ page, searchValue }) =>
        `/search:art?q=${searchValue}&pageNo=${page}`,
      providesTags: ["searchValue"],
    }),
  }),
});

export const {
  useGetArtsQuery,
  useGetSingleArtQuery,
  useGetArtistsQuery,
  useGetDashboardQuery,
  useGetOwnersQuery,
  useGetOrdersQuery,
  useGetSingleOrderQuery,
} = Api;
export const { useGetNFTsQuery } = nftAcount;
export const { useGetSearchQuery } = SearchApi;
export const { useGetProfileDetailQuery, useGetProfileArtsQuery } =
  ProfileDetail;
export const { useGetArtistsMutation, useGetArtsMutation } = ProtectAPIs;
