import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../store/auth/authSlice";
import { jwtDecode } from "jwt-decode";


const Guard = () => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const accessTokenExpiration =accessToken? jwtDecode(`${accessToken}`)?.exp:0;
  const currentTime = Math.floor(Date.now() / 1000);
  const dispacth = useDispatch();
  console.log(
    "🚀 ~ file: index.tsx:15 ~ Guard ~ accessTokenExpiration:",
    accessTokenExpiration
  );
  const pathname = useLocation().pathname;
  const nav = useNavigate();

  const AuthRout= pathname=='/auth'

  useEffect(() => {
    if (
      (!AuthRout && !accessToken)
    ) {
      nav("/auth");
      dispacth(logout());

    }
    if (accessToken && accessTokenExpiration < currentTime) {
      dispacth(logout());
      if (!AuthRout) {
        nav("/auth");
      }
    }

    // if (!accessToken && particle.auth.isLogin()) {
    //   dispacth(logout());
    //   particleLogout();
    // }
  }, [accessToken, pathname]);

  return null;
};

export default Guard;
